import 'moment/locale/fr';
import isPropValid from '@emotion/is-prop-valid';
import {
  AppService,
  Area,
  AreaService,
  CondensedRideTrace,
  CyclabilityZone,
  CyclabilityZoneService,
  DashboardPages,
  Event,
  ExportData,
  ExportDataProjection,
  ExportDataService,
  Geogroup,
  GeogroupService,
  HttpService,
  ParkingTypes,
  Partner,
  PartnerContract,
  PartnerContractTemplate,
  PartnerService,
  PoiCategory,
  ReportType,
  ReportTypeService,
  RideTheme,
  StatsService,
  TBaseLayer,
  TPoiCategoryCode,
  TSectionFeatureCollection,
  User,
  UserService,
  companyContractCodes,
  poiCategoryCodes,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import {
  ArcElement,
  BarController,
  BarElement,
  Chart,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PieController,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { HelmetProvider, HelmetServerState } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import { environment } from '../environment';
import { TAccidentType } from '../pages/cartographic-data/models/accidentology';
import { IDashboardTab } from '../pages/dashboard-page';

import { AppContext } from './context';
import i18n from './i18n';
import iconVariants from './icon-variants';
import Router from './router';
import theme from './theme';
import './tracking';

import { Map } from '!maplibre-gl';

Chart.register(
  BarController,
  LineController,
  PieController,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  LinearScale,
  TimeScale,
  Legend,
  Tooltip,
  Title,
);

Chart.defaults.font.family = '"Roboto", "Helvetica", "Arial", sans-serif';

const helmetContext = {} as {
  helmet: HelmetServerState;
};

function App(): JSX.Element {
  // app context
  const appInitializing = useRef(false);
  const [highlightedEvent, setHighlightedEvent] = useState<Event>();

  // navigation context
  const [currentPage, setCurrentPage] = useState<DashboardPages>();
  const [currentTab, setCurrentTab] = useState<IDashboardTab>();

  // user context
  const [currentUser, setCurrentUser] = useState<User | null>();

  // partner context
  const [partnerContractTemplates, setPartnerContractTemplates] =
    useState<PartnerContractTemplate[]>();
  const [allPartners, setAllPartners] = useState<Partner[]>();
  const [userPartners, setUserPartners] = useState<Partner[]>();
  const [currentPartner, setCurrentPartner] = useState<Partner>();
  const [lastVisitedPartner, setLastVisitedPartner] = useState<Partner>();
  const [currentPartnerArea, setCurrentPartnerArea] = useState<Area | null>();
  const [currentPartnerContracts, setCurrentPartnerContracts] = useState<PartnerContract[]>();
  const [currentPartnerActiveContracts, setCurrentPartnerActiveContracts] =
    useState<PartnerContract[]>();
  const [currentPartnerGeogroup, setCurrentPartnerGeogroup] = useState<Geogroup | null>();
  const [currentPartnerCyclabilityZones, setCurrentPartnerCyclabilityZones] = useState<
    CyclabilityZone[] | null
  >();
  const [createPartnerDialogOpen, openCreatePartnerDialog] = useState(false);
  const [editPartnerDialogOpen, openEditPartnerDialog] = useState(false);
  const [partnerToEdit, setPartnerToEdit] = useState<Partner | null>(null);
  const [initialized, setInitialized] = useState(false);
  const [exportData, setExportData] = useState<ExportData[]>();
  const [exportDataProjections, setExportDataProjections] = useState<ExportDataProjection[]>();
  const [sections, setSections] = useState<TSectionFeatureCollection>();
  const {
    cancellablePromise: cancellableCyclabilityZonesPromise,
    cancelPromises: cancelCyclabilityZonesPromises,
  } = useCancellablePromise();
  const { cancellablePromise: cancellableSectionsPromise, cancelPromises: cancelSectionPromises } =
    useCancellablePromise();

  // report context
  const [reportTypes, setReportTypes] = useState<ReportType[]>();
  const [loadingReportTypes, setLoadingReportTypes] = useState(false);

  // community context
  const [geogroups, setGeogroups] = useState<Geogroup[]>();

  // ride context
  const [rideThemes, setRideThemes] = useState<RideTheme[]>();
  const [rideTraces, setRideTraces] = useState<CondensedRideTrace[]>();

  // poi context
  const [poiCategories, setPoiCategories] = useState<PoiCategory[]>();
  const [selectedPoiCategories, selectPoiCategories] = useState<{
    [key in TPoiCategoryCode]?: boolean;
  }>(() => {
    const _selectedCategories: { [key in TPoiCategoryCode]?: boolean } = {};
    let item: string | null = null;
    try {
      item = localStorage.getItem('poi_categories');
    } catch {
      console.error('localStorage access is denied');
    }
    const _poiCategoryCodes = item ? item.split(',') : [];

    poiCategoryCodes.forEach((code) => {
      if (_poiCategoryCodes.indexOf(code) > -1)
        _selectedCategories[code as TPoiCategoryCode] = true;
    });

    return _selectedCategories;
  });

  // map context
  const [currentMap, setCurrentMap] = useState<Map | null>();
  const [baseLayer, setBaseLayer] = useState<TBaseLayer>('geovelo');
  const [mapZoom, setMapZoom] = useState<number>();
  const [facilitiesShowed, toggleFacilities] = useState(() => {
    let facilitiesItem: string | null = null;
    try {
      facilitiesItem = localStorage.getItem('display_facilities');
    } catch {
      console.error('localStorage access is denied');
    }

    return facilitiesItem !== 'false';
  });
  const [countersShowed, toggleCounters] = useState(false);
  const [parkingsToggleEnabled, enableParkingsToggle] = useState(false);
  const [parkingsShowed, toggleParkings] = useState<{ [type in ParkingTypes]: boolean }>({
    arch: true,
    free: true,
    private: true,
    rack: true,
    secure: true,
    sheltered: true,
    locked: true,
  });
  const [parkingRequestsShowed, toggleParkingRequests] = useState(false);
  const [potholesShowed, togglePotholes] = useState(false);
  const [stoppingAreasShowed, toggleStoppingAreas] = useState(true);
  const [averageSpeedsShowed, toggleAverageSpeeds] = useState(true);
  const [accidentZonesShowed, toggleAccidentZones] = useState(true);
  const [incidentsShowed, toggleIncidents] = useState<{
    accidents: { [key in TAccidentType]: boolean };
    blackSpots: boolean;
    reports: boolean;
    suddenBrakings: boolean;
  }>({
    accidents: { deadly: false, hospitalized: false, injured: false },
    blackSpots: false,
    reports: false,
    suddenBrakings: false,
  });
  const [originDestinationZonesShowed, toggleOriginDestinationZones] = useState(true);
  const [originDestinationFlowsShowed, toggleOriginDestinationFlows] = useState(true);

  useEffect(() => {
    AppService.environment = environment;
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (currentUser) {
      getUserPartners();
      if (currentUser.isGeovelo) getAllPartners();
    } else if (currentUser === null) {
      setPartnerContractTemplates(undefined);
      setAllPartners(undefined);
      setUserPartners(undefined);
      setRideThemes(undefined);
      setRideTraces(undefined);
      setCurrentPartner(undefined);
      setLastVisitedPartner(undefined);
      setCurrentPartnerContracts(undefined);
      setCurrentPartnerActiveContracts(undefined);
      setCurrentPartnerGeogroup(undefined);
      setCurrentPartnerCyclabilityZones(undefined);
      setExportData(undefined);
      setExportDataProjections(undefined);

      HttpService.partner = null;
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentPartner) {
      getPartnerArea(currentPartner);
      getPartnerContracts(currentPartner);
      getPartnerCommunity(currentPartner);
      if (currentPartner?.dashboardTabsPermissions.facilities !== 'none') getExportData();
    } else {
      setCurrentPartnerContracts(undefined);
      setCurrentPartnerActiveContracts(undefined);
      setCurrentPartnerGeogroup(undefined);
      setCurrentPartnerCyclabilityZones(undefined);
      setExportData(undefined);
      setExportDataProjections(undefined);
    }
  }, [currentPartner]);

  useEffect(() => {
    function handleZoom() {
      if (currentMap) setMapZoom(currentMap.getZoom());
      else setMapZoom(undefined);
    }

    currentMap?.on('zoomend', handleZoom);
    handleZoom();

    return () => {
      currentMap?.off('zoomend', handleZoom);
    };
  }, [currentMap]);

  async function getAllPartners() {
    try {
      const templates = await PartnerService.getContractTemplates();
      setPartnerContractTemplates(
        templates.filter(
          ({ code }) => code && !['entreprise', ...companyContractCodes].includes(code),
        ),
      );

      const _partners: Partner[] = [];
      let hasNext = true;
      let page = 1;

      while (hasNext) {
        const { partners, next } = await PartnerService.getPartners({
          isAdmin: true,
          page: page++,
          query: '{id, code, title, icon, area, contracts}',
          templates,
        });

        _partners.push(...partners);
        hasNext = Boolean(next);
      }

      setAllPartners(
        _partners.filter(({ contracts }) => {
          return !!contracts?.find(
            ({ contractTemplate: { code } }) => code.indexOf('entreprise') === -1,
          );
        }),
      );
    } catch (err) {
      console.error(err);
      setAllPartners([]);
      setPartnerContractTemplates([]);
    }
  }

  async function getUserPartners() {
    try {
      const partners = (
        await UserService.getPartners({
          isAdmin: currentUser?.isGeovelo,
          isSupport: currentUser?.isSupport,
        })
      )
        .filter(({ contracts }) => {
          return !!contracts?.find(
            ({ contractTemplate: { code } }) => code.indexOf('entreprise') === -1,
          );
        })
        .sort((a, b) => a.title.localeCompare(b.title));

      setUserPartners(partners);
      setLastVisitedPartner(partners.find(({ isDefault }) => isDefault) || partners[0]);
    } catch (err) {
      console.error(err);
      setUserPartners([]);
    }
  }

  async function getPartnerArea({ area: areaId }: Partner) {
    if (!areaId) {
      setCurrentPartnerArea(null);
      return;
    }

    try {
      const area = await AreaService.getArea(areaId, { query: '{id, geo_polygon}' });

      setCurrentPartnerArea(area);
    } catch (err) {
      console.error(err);
      setCurrentPartnerArea(null);
    }
  }

  async function getPartnerContracts(partner: Partner) {
    try {
      const contracts = await PartnerService.getContracts(partner.id);

      contracts.sort((a, b) => {
        if (a.endDateTime && b.endDateTime)
          return a.endDateTime.isSame(b.endDateTime)
            ? a.title.localeCompare(b.title)
            : a.endDateTime.isBefore(b.endDateTime)
              ? 1
              : -1;

        if (a.endDateTime) return 1;
        if (b.endDateTime) return -1;

        return a.title.localeCompare(b.title);
      });

      const now = moment();

      setCurrentPartnerContracts(contracts);
      setCurrentPartnerActiveContracts(
        [...contracts].filter(
          ({ startDateTime, endDateTime }) =>
            startDateTime.isSameOrBefore(now) && endDateTime?.isSameOrAfter(now),
        ),
      );
    } catch (err) {
      console.error(err);
      setCurrentPartnerContracts([]);
      setCurrentPartnerActiveContracts([]);
    }
  }

  async function getPartnerCommunity(partner: Partner) {
    if (!partner.geoGroupId) {
      setCurrentPartnerGeogroup(null);
      return;
    }

    try {
      const geogroup = await GeogroupService.getGeogroup({ geoGroupId: partner.geoGroupId });

      setCurrentPartnerGeogroup(geogroup);
    } catch (err) {
      console.error(err);
      setCurrentPartnerGeogroup(null);
    }
  }

  async function getPartnerCyclabilityZones(partner: Partner) {
    cancelCyclabilityZonesPromises();
    setCurrentPartnerCyclabilityZones(undefined);

    if (
      (partner.administrativeLevel !== 'epci' && partner.administrativeLevel !== 'department') ||
      !partner.area
    ) {
      setCurrentPartnerCyclabilityZones(null);
      return;
    }

    try {
      const { zones: _cyclabilityZones } = await cancellableCyclabilityZonesPromise(
        CyclabilityZoneService.getZones({
          administrativeLevel: partner.administrativeLevel === 'department' ? 'EPCI' : 'CITY',
          partnerCode: partner.code,
          query: '{ id, code, name, administrative_level, geo_polygon_simplified }',
          rowsPerPage: 100,
        }),
      );

      setCurrentPartnerCyclabilityZones(_cyclabilityZones);
    } catch (err) {
      console.error(err);
      setCurrentPartnerCyclabilityZones(null);
    }
  }

  async function getReportTypes() {
    if (!reportTypes && !loadingReportTypes) {
      setLoadingReportTypes(true);

      const reportTypes = await ReportTypeService.getReportTypes();

      setReportTypes(reportTypes);
      setLoadingReportTypes(false);
    }
  }

  async function getGeogroups() {
    try {
      const { geogroups: _geogroups } = await GeogroupService.getGeogroups({
        query: '{id, title, type, area}',
      });

      setGeogroups(_geogroups.filter(({ type }) => type === 'city'));
    } catch (err) {
      console.error(err);
    }
  }

  async function getPartnerSections(partner?: Partner) {
    cancelSectionPromises();
    setSections(undefined);

    if (!partner) return;
    if (
      partner.dashboardTabsPermissions.cyclability === 'none' &&
      partner.dashboardTabsPermissions.usageFluidity === 'none' &&
      partner.dashboardTabsPermissions.usageRoadsUse === 'none'
    ) {
      return;
    }

    try {
      const _sections = await cancellableSectionsPromise(
        StatsService.getOsmWaySections(partner.id),
      );

      setSections(_sections);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        throw err;
      }
    }
  }

  async function getExportData() {
    try {
      setExportData(await ExportDataService.getExportData());
      setExportDataProjections(await ExportDataService.getExportDataProjections());
    } catch (err) {
      console.error(err);
    }
  }

  if (!initialized) return <></>;

  return (
    <AppContext.Provider
      value={{
        app: {
          initializing: appInitializing,
          highlightedEvent,
        },
        navigation: { currentPage, currentTab },
        user: { current: currentUser },
        partner: {
          contractTemplates: partnerContractTemplates,
          all: allPartners,
          list: userPartners,
          current: currentPartner,
          lastVisited: lastVisitedPartner,
          contracts: currentPartnerContracts,
          activeContracts: currentPartnerActiveContracts,
          currentArea: currentPartnerArea,
          currentGeogroup: currentPartnerGeogroup,
          cyclabilityZones: currentPartnerCyclabilityZones,
          createDialogOpen: createPartnerDialogOpen,
          editDialogOpen: editPartnerDialogOpen,
          partnerToEdit,
          exportData,
          exportDataProjections,
          sections,
        },
        poi: { categories: poiCategories, selectedCategories: selectedPoiCategories },
        report: { types: reportTypes },
        community: { geogroups },
        map: {
          current: currentMap,
          baseLayer,
          zoom: mapZoom,
          facilitiesShowed,
          countersShowed,
          parkingsToggleEnabled,
          parkingsShowed,
          parkingRequestsShowed,
          potholesShowed,
          stoppingAreasShowed,
          averageSpeedsShowed,
          accidentZonesShowed,
          incidentsShowed,
          originDestinationZonesShowed,
          originDestinationFlowsShowed,
        },
        ride: { themes: rideThemes, traces: rideTraces },
        actions: {
          setHighlightedEvent,
          setCurrentPage,
          setCurrentTab,
          setCurrentUser,
          setPartnerContractTemplates,
          setRideThemes,
          setRideTraces,
          setUserPartners,
          setCurrentPartner,
          setLastVisitedPartner,
          setCurrentPartnerContracts,
          setCurrentPartnerActiveContracts,
          openCreatePartnerDialog,
          openEditPartnerDialog,
          setPartnerToEdit,
          getReportTypes,
          getGeogroups,
          getPartnerSections,
          getPartnerCyclabilityZones,
          setCurrentMap,
          setBaseLayer,
          toggleFacilities,
          toggleCounters,
          enableParkingsToggle,
          toggleParkings,
          toggleParkingRequests,
          togglePotholes,
          toggleStoppingAreas,
          toggleAverageSpeeds,
          toggleAccidentZones,
          toggleIncidents,
          toggleOriginDestinationZones,
          toggleOriginDestinationFlows,
          selectPoiCategories,
          setPoiCategories,
        },
      }}
    >
      <I18nextProvider i18n={i18n}>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <StyleSheetManager
              shouldForwardProp={(propName, elementToBeRendered) =>
                typeof elementToBeRendered === 'string' ? isPropValid(propName) : true
              }
            >
              <SnackbarProvider
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                iconVariant={iconVariants}
                maxSnack={3}
              >
                <HelmetProvider context={helmetContext}>
                  <Router />
                </HelmetProvider>
              </SnackbarProvider>
            </StyleSheetManager>
          </ThemeProvider>
        </MuiThemeProvider>
      </I18nextProvider>
    </AppContext.Provider>
  );
}

export default App;
